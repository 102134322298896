import React from 'react'
import Head from '../components/head'
import Layout from '../components/layout'


const About = () => {
    return(
        <Layout>
            <Head title = "About" />
            <h1>About</h1>

        </Layout>
    )
}

export default About