/**
 * Footer
 */

 import React from 'react';
 //import { graphql, useStaticQuery } from 'gatsby'
 import * as footerStyles from './footer.module.css'
 
 
 const Footer = () => {
   {/*
   const metaData = useStaticQuery(graphql`
   query myQuery {
       site {
         siteMetadata {
           author
           twitter
         }
       }
     }
   `)
    */}
   return (
    <footer className={footerStyles.footer}>
      <p> 
        <a href="mailto:mark.webster@wanadoo.fr">Email </a> |&nbsp; 
        <a href="https://twitter.com/motiondesign_01">Twitter</a> |&nbsp; 
        <a href="http://simonrenaud.fr/">Font</a>
        <span className={footerStyles.copyright}> © 2021 Mark Webster</span>
      </p>
      
    </footer>
   )
 }
 
 export default Footer
 