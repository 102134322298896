import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import * as headerStyles from './header.module.css'

const Header = () => {
    const metaDataTitle = useStaticQuery(graphql`
    query MyQuery {
        site {
          siteMetadata {
            title
            twitter
          }
        }
      }
    `)
    return(
   
        <header>
            <Link className={headerStyles.mainTitle} to='/'>
                { metaDataTitle.site.siteMetadata.title }
            </Link>
            <nav>
                <ul className={headerStyles.navMenu}>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/'>Home</Link>
                    </li>
                    {/*
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/about'>About</Link>
                    </li>
                    */}
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/works'>Artwork</Link>
                    </li>
                    <li>
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to='/research'>Research</Link>
                    </li>

                </ul>
            </nav>
        </header>
    )
}

export default Header